<template>
  <CRow>
    <CCol col="12" lg="12">
      <CAlert color="danger" closeButton :show.sync="dismissCountDown" fade>
        {{ err_msg }}
      </CAlert>
      <CCard>
        <CCardHeader>
          <strong>{{ $lang.discount.crud.edit }}</strong>
        </CCardHeader>
        <CForm novalidate @submit.prevent="onSubmit">
          <CCardBody>
            <CRow>
              <CCol md="6">
                <div class="form-group">
                  <label class="form__label"
                  >{{ $lang.discount.form.type }}
                    <required_span
                    />
                  </label>
                  <v-select
                      :options="typeOptions"
                      v-model="discount.type"
                      @option:selected="changedType"
                  ></v-select>
                  <small
                      class="error"
                      v-if="$v.discount.type.$error && !$v.discount.type.required"
                  >{{ $lang.discount.validation.required.type }}</small
                  >
                </div>
              </CCol>
              <CCol md="6">
                <div class="form-group">
                  <label class="form__label"
                  >{{ $lang.discount.form.is_flat }}
                    <required_span/>
                  </label
                  >&nbsp;&nbsp;
                  <input
                      type="checkbox"
                      :placeholder="$lang.discount.form.is_flat"
                      :value="1"
                      @change="changedIsFlat"
                      v-model="discount.is_flat"
                  />
                </div>
              </CCol>
              <CCol md="6" :hidden="persontageField">
                <div class="form-group">
                  <label class="form__label"
                  >{{ $lang.discount.form.percentage }}
                    <required_span
                    />
                  </label>
                  <input
                      :maxlength="maxlength.percentage"
                      type="text"
                      :placeholder="$lang.discount.form.percentage"
                      class="form-control"
                      v-model="discount.percentage"
                  />
                  <small
                      class="error"
                      v-if="
                      $v.discount.percentage.$error &&
                      !$v.discount.percentage.required
                    "
                  >{{ $lang.discount.validation.required.percentage }}</small
                  >
                </div>
              </CCol>
              <CCol md="6" :hidden="amountField">
                <div class="form-group">
                  <label class="form__label"
                  >{{ $lang.discount.form.amount }}
                    <required_span
                    />
                  </label>
                  <input
                      :maxlength="maxlength.amount"
                      type="text"
                      :placeholder="$lang.discount.form.amount"
                      class="form-control"
                      v-model="discount.amount"
                  />
                  <small
                      class="error"
                      v-if="
                      $v.discount.amount.$error && !$v.discount.amount.required
                    "
                  >{{ $lang.discount.validation.required.amount }}</small
                  >
                </div>
              </CCol>
              <CCol md="6">
                <div class="form-group">
                  <label class="form__label">{{ $lang.discount.form.noOfUsers }}</label>
                  <input :maxlength="maxlength.noOfUsers" type="number" :placeholder="$lang.discount.form.noOfUsers"
                         class="form-control" v-model="discount.noOfUsers"/>
                </div>
              </CCol>
              <CCol md="6">
                <div class="form-group">
                  <label class="form__label"
                  >{{ $lang.discount.form.start_date }}
                    <required_span
                    />
                  </label>
                  <input
                      type="date"
                      class="form-control"
                      v-model="discount.start_date"
                  />
                  <small
                      class="error"
                      v-if="
                      $v.discount.start_date.$error &&
                      !$v.discount.start_date.required
                    "
                  >{{ $lang.discount.validation.required.start_date }}</small
                  >
                </div>
              </CCol>
              <CCol md="6">
                <div class="form-group">
                  <label class="form__label"
                  >{{ $lang.discount.form.end_date }}
                    <required_span
                    />
                  </label>
                  <input
                      type="date"
                      class="form-control"
                      v-model="discount.end_date"
                  />
                  <small
                      class="error"
                      v-if="
                      $v.discount.end_date.$error &&
                      !$v.discount.end_date.required
                    "
                  >{{ $lang.discount.validation.required.end_date }}</small
                  >
                </div>
              </CCol>
              <CCol md="6" :hidden="userField">
                <div class="form-group">
                  <label class="form__label"
                  >{{ $lang.discount.form.user }}
                    <required_span
                    />
                  </label>
                  <v-select
                      :options="userOptions"
                      v-model="discount.user"
                      multiple
                  ></v-select>
                  <small
                      class="error"
                      v-if="$v.discount.user.$error && !$v.discount.user.required"
                  >{{ $lang.discount.validation.required.user }}</small
                  >
                </div>
              </CCol>
              <CCol md="6" :hidden="companyDropdwonField">
                <div class="form-group">
                  <label class="form__label"
                  >{{ $lang.discount.form.company_name }}
                    <required_span
                    />
                  </label>
                  <v-select
                      :options="companyOptions"
                      v-model="discount.company_id"
                  ></v-select>
                  <a @click="changedNewCompany()"><small>Add Partner Agency</small></a>
                  <a @click="refreshCompanyList()" class="float-right"
                  ><small>Refresh</small></a
                  >
                  <br/>
                  <small
                      class="error"
                      v-if="
                      $v.discount.company_id.$error &&
                      !$v.discount.company_id.required
                    "
                  >{{
                      $lang.discount.validation.required.company_name
                    }}</small
                  >
                </div>
              </CCol>
            </CRow>
          </CCardBody>
          <CCardFooter>
            <CButton
                type="submit"
                size="sm"
                color="primary"
                :disabled="submitted"
            >
              <CIcon name="cil-check-circle"/>
              {{ $lang.buttons.general.crud.submit }}
            </CButton> &nbsp;
            <CButton color="danger" size="sm" @click="cancel">
              <CIcon name="cil-ban"/>
              {{ $lang.buttons.general.crud.cancel }}
            </CButton>
          </CCardFooter>
        </CForm>
      </CCard>
    </CCol>
  </CRow>
</template>
<script>
import Vue from "vue";
import VueForm from "vue-form";
import store from "/src/store/store.js";
import {discount, users, company} from "/src/store/url.js";
import {Mixin} from "/src/mixins/index.js";
import options from "/src/validations/validations.js";
import VueNotifications from "vue-notifications";
import {required, requiredIf} from "vuelidate/lib/validators";
import required_span from "../../components/layouts/general/required-span";
import VueToasted from "vue-toasted";
import moment from "moment";
import VueTelInput from "vue-tel-input-fix";
import "vue-tel-input-fix/dist/vue-tel-input.css";
import {Maxlength} from "../../store/maxlength";

function toast({title, message, type, timeout, cb}) {
  if (type === VueNotifications.types.warn) type = "show";
  return Vue.toasted[type](message, {
    duration: timeout,
    position: "top-left",
  });
}

Vue.use(VueToasted);
Vue.use(VueTelInput);
const optionss = {
  success: toast,
  error: toast,
  info: toast,
  warn: toast,
  position: "top-center",
};
Vue.use(VueNotifications, optionss);
Vue.use(VueForm, options);

export default {
  name: "DiscountEdit",
  mixins: [Mixin],
  components: {
    required_span,
  },
  data() {
    return {
      bindProps: {
        mode: "international",
        placeholder: "Contact No.",
        defaultCountry: "IN",
        dropdownOptions: {
          showFlags: true,
        },
        validCharactersOnly: true,
      },
      submitted: false,
      companyDropdwonField: "",
      amountField: "",
      persontageField: "",
      moduleCompany: company,
      userField: "",
      err_msg: "",
      dismissCountDown: 0,
      self: this,
      module: discount,
      moduleUser: users,
      userOptions: [],
      companyOptions: [],
      editId: this.$route.params.id,
      discount: {
        is_flat: 0,
        company_id: "",
        percentage: "",
        amount: "",
        user: [],
        type: "",
        noOfUsers: "",
      },
      maxlength: {
        percentage: Maxlength.discount.percentage,
        amount: Maxlength.discount.amount,
        noOfUsers: Maxlength.discount.noOfUsers,
      },
      typeOptions: [
        {value: "Internal", label: "Internal"},
        {value: "External", label: "External"},
      ],
    };
  },
  validations: {
    discount: {
      type: {
        required,
      },
      start_date: {
        required,
      },
      end_date: {
        required,
      },
      amount: {
        requiredIf: requiredIf(function () {
          return this.discount.is_flat === 1;

        }),
      },
      percentage: {
        requiredIf: requiredIf(function () {
          return this.discount.is_flat === 0;

        }),
      },
      user: {
        requiredIf: requiredIf(function () {
          return this.discount.type.value === "Internal";

        }),
      },
      company_id: {
        requiredIf: requiredIf(function () {
          return this.discount.type.value === "External";

        }),
      },
    },
  },
  mounted() {
    let self = this;
    store.commit("showLoader", false); // Loader Off
    self.dismissCountDown = 0;
    axios.get('/users/lists').then((response) => {
      response.data.data.map(function (value, key) {
        self.userOptions.push({value: value.id, label: value.name});
      });
    });
    axios.get(this.listUrlApi(this.moduleCompany)).then((response) => {
      response.data.data.map(function (value, key) {
        self.companyOptions.push({value: value.id, label: value.companyName});
      });
    });
    axios
        .get(this.viewUrlApi(this.module, self.editId))
        .then((response) => {
          let discount = response.data.data;
          self.discount.is_flat = discount.isFlat;
          self.discount.noOfUsers = discount.noOfUsers;
          if (discount.isFlat === 1) {
            self.amountField = false;
            self.persontageField = true;
            self.discount.percentage = "";
            self.discount.amount = discount.amount;
          } else {
            self.amountField = true;
            self.persontageField = false;
            self.discount.percentage = discount.percentage;
            self.discount.amount = "";
          }
          if (discount.type === "External") {
            self.userField = true;
            self.discount.user = {};
            self.companyDropdwonField = false;
          } else {
            self.userField = false;
            self.discount.user = discount.userList;
            self.companyDropdwonField = true;
          }
          self.discount.type = {value: discount.type, label: discount.type};
          self.discount.company_id = {
            value: discount.discountCompanies.id,
            label: discount.discountCompanies.companyName,
          };
          self.discount.start_date = discount.startDate;
          self.discount.end_date = discount.endDate;
          store.commit("showLoader", false); // Loader Off
        })
        .catch(function (error) {
          store.commit("showLoader", false); // Loader Off
        });
  },
  methods: {
    refreshCompanyList() {
      let self = this;
      self.companyOptions = [];
      self.discount.company_id = {};
      axios.get(this.listUrlApi(this.moduleCompany)).then((response) => {
        response.data.data.map(function (value, key) {
          self.companyOptions.push({
            value: value.id,
            label: value.companyName,
          });
        });
      });
    },
    getHumanDate: function (date) {
      return moment(date, "YYYY-MM-DD").format("DD/MM/YYYY");
    },
    changedNewCompany() {
      let routeData = this.$router.resolve({name: "Partner Agency Create"});
      window.open(routeData.href, "_blank");
    },
    changedIsFlat(e) {
      let self = this;
      self.$v.$reset();
      this.v = e.target.checked;
      if (this.v) {
        self.discount.is_flat = 1;
        self.amountField = false;
        self.persontageField = true;
      } else {
        self.discount.is_flat = 0;
        self.amountField = true;
        self.persontageField = false;
      }
    },
    changedType(item) {
      let self = this;
      self.$v.$reset();
      if (item.value === "External") {
        self.userField = true;
        self.companyDropdwonField = false;
      } else {
        self.userField = false;
        self.companyDropdwonField = true;
      }
    },
    sendAlert() {
      this.$root.$emit("alert", "test1", "test2");
    },
    onSubmit() {
      let self = this;
      this.$v.discount.$touch();
      if (this.$v.discount.$invalid) {
        self.submitted = false;
        this.submitStatus = "ERROR";
      } else {
        self.submitted = true;
        this.submitStatus = "PENDING";
        setTimeout(() => {
          let self = this;
          const formData = new FormData();
          let userId = [];
          if (self.discount.type.value === "Internal") {
            self.discount.user.map(function (value, key) {
              userId[key] = value.value;
            });
          }
          formData.append("isFlat", self.discount.is_flat);
          formData.append("percentage", self.discount.percentage);
          formData.append("amount", self.discount.amount);
          formData.append("companyName", self.discount.company_name);
          formData.append("userId", userId);
          formData.append("type", self.discount.type.value);
          formData.append("endDate", self.discount.end_date);
          formData.append("startDate", self.discount.start_date);
          if (self.discount.company_id.value && self.discount.company_id !== 0) {
            formData.append("companyId", self.discount.company_id.value);
          }
          if (self.discount.noOfUsers !== 0 || self.discount.noOfUsers !== '') {
            formData.append("noOfUsers", self.discount.noOfUsers);
          }
          axios
              .post(this.updateUrlweb(this.module, self.editId), formData)
              .then(function (response) {
                self.submitted = false;
                if (response.data.code === 200) {
                  localStorage.setItem("showAlert", "This is my alert");
                  self.sendAlert();
                  self.$router.push({path: "/discount"});
                } else {
                  self.err_msg = response.data.response.message;
                  self.dismissCountDown = 10;
                }
              })
              .catch(function (error) {
                let data;
                if (error.response.data.error) {
                  data = error.response.data.error.toString();
                } else {
                  data = error.response.data.message;
                }
                self.err_msg = data;
                self.dismissCountDown = 10;
                self.submitted = false; //Enable Button
              });
        }, 500);
      }
    },
    cancel() {
      this.usersOpened
          ? this.$router.go(-1)
          : this.$router.push({path: "/discount"});
    },
  },
};
</script>
<style>
div .error {
  color: red;
}

.hasError label {
  color: red;
}
</style>
